<template>
	<div class="box">
		<headers />
		<div class="login-box">
			<form action="" class="form-con">
				<div class="zh">
					<span>账号</span>
					<input type="text" v-model.trim="accont" />
				</div>
				<div class="pwd">
					<span>密码</span>
					<input type="password" name="" id="" v-model.trim="pwd" />
				</div>
				<el-button class="login-btn" @click="login" v-loading='loginflag'
					element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255,1)"
					element-loading-text="正在登录">登录</el-button>
			</form>
		</div>
	</div>
</template>

<script>
	import headers from '../../components/headers.vue';
	export default {
		components: {
			headers
		},
		data() {
			return {
				accont: '',
				pwd: '',
				loginflag: false,
			};
		},
		methods: {
			login() {
				this.loginflag = true
				if (this.accont == '' || this.pwd == '') {
					this.$message({
						message: '账号或密码不能为空',
						type: 'error'
					});
					this.loginflag = false
				} else {
					this.$postToken('user/login-by-pwd', {
						phone: this.accont,
						password: this.pwd
					}).then(res => {
						if (res.data.code === 200) {
							this.loginflag = false
							this.$message({
								message: res.data.msg,
								type: 'success'
							});
							localStorage.setItem('userinfo', JSON.stringify(res.data.result));
							this.$router.push({
								path: '/workbench'
							});
						} else {
							this.$message({
								message: res.data.msg,
								type: 'error'
							});
							this.loginflag = false
						}
					});
				}
			}
		}
	};
</script>

<style scoped>
	.box {
		height: 100vh;
		width: 100vw;
		background: url(../../assets/images/bg.png) no-repeat;
	}

	.login-box {
		height: 100%;
		display: flex;
		/* align-items: center; */
		justify-content: flex-end;
	}

	.zh,
	.pwd {
		display: flex;
		flex-direction: column;
	}

	.zh span,
	.pwd span {
		font-size: 20px;
		line-height: 23px;
		color: #ffffff;
		display: block;
		margin-bottom: 20px;
	}

	.zh input,
	.pwd input {
		color: #fff;
		font-size: 16px;
		padding-left: 1em;
		width: 420px;
		height: 61px;
		box-sizing: border-box;
		background-color: #011029;
		background: linear-gradient(360deg, #192c46 0%, #041935 100%);
		border-radius: 4px 4px 4px 4px;
		border: none;
		outline: none;
	}

	.login-btn {
		display: block;
		width: 100%;
		background: #159aff;
		border-radius: 4px 4px 4px 4px;
		border: none;
		color: #fff;
		height: 61px;
		font-size: 20px;
		/* line-height: 61px; */
	}

	.form-con {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-right: 260px;
		margin-top: 215px;
		padding: 42px;
		width: 504px;
		height: 473px;
		box-sizing: border-box;
		background: rgba(0, 111, 195, 0.06);
		border-radius: 6px 6px 6px 6px;
		border: 2px solid;
		border-image: linear-gradient(45deg, rgba(21, 154, 255, 1), rgba(102, 225, 223, 0)) 2 2;
	}
</style>